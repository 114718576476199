import OperationTable from "./OperationTable"
import OperationForm from "./OperationForm"
import { useState,useEffect } from "react"
import { useParams } from "react-router-dom";
import WinningNumberTable from "./WinningNumberTable";
import axios from "axios"

  export default function OperationIndex(props) {

    const { userId } = useParams();

    const [player,setPlayer]=useState(null);
    const [ticketList,setTicketList]=useState(null);
    const [winningNumberList,setWinningNumberList]=useState(null);

    const getWinningNumber = async () => {
        try {
          const requestData = {
            tables: ["winningnumber"],
            joins: [],
            conditions: [],
            selectedColumns: ["*"],
          };
    
          const response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/getJoinedData`,
            requestData
          );
          console.log("result winning number", response.data); // Itt kezelheti a választ
          setWinningNumberList(response.data);
        } catch (error) {
          console.error(error);
        }
      };

    
    const getUserInfo = async () => {
      try {
        const requestData = {
          tables: ["player"],
          joins: [],
          conditions: [`RoleId = 2`],
          selectedColumns: ["*"],
        };
  
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/getJoinedData`,
          requestData
        );
        console.log("result player", response.data); // Itt kezelheti a választ
        setPlayer(response.data[0]);
      } catch (error) {
        console.error(error);
      }
    };

    const getTicketList = async () => {
      try {
        const requestData = {
          tables: ["ticket","player"],
          joins: ["ticket.PlayerId = player.playerId"],
          conditions: [],
          selectedColumns: ["*"],
        };
    
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/getJoinedData`,
          requestData
        );
        console.log("result", response.data); // Itt kezelheti a választ
    {/* 
   const sortedData = response.data.sort((a, b) => {
          if (a.GeneratedTicket === "Y" && b.GeneratedTicket !== "Y") {
            return 1; // A Y-t tartalmazó elemek előre kerülnek
          } else if (a.GeneratedTicket !== "Y" && b.GeneratedTicket === "Y") {
            return -1; // Az Y-t nem tartalmazó elemek az előbbiek után következnek
          } else {
            // Ha mindkét elemnek azonos a GeneratedTicket értéke vagy mindkettő Y vagy mindkettő nem Y
            // Ebben az esetben a createdDate szerinti csökkenő sorrendbe rendezzük őket
            return new Date(b.createdDate) - new Date(a.createdDate);
          }
        });
      */}
       
    
        setTicketList(response.data); // Ez fogja beállítani a rendezett adatokat az állapotban
      } catch (error) {
        console.error(error);
      }
    };
  
    
    useEffect(() => {
      getTicketList();
      getUserInfo();
      getWinningNumber();
    }, []);
  
    useEffect(() => {
      getTicketList();
      getUserInfo();
      getWinningNumber();
    }, [props]);

    useEffect(() => {
      if (userId) {
        getTicketList();
        getUserInfo();
        getWinningNumber();
      }
    }, [userId]);


    return (
      <>
    <div>
   
  

    </div>
   <div className="fixed inset-0 top-16 grid grid-cols-4 grid-rows-[auto_1fr] gap-4 bg-gray-100 p-4  " 
   >
 
  
       <OperationForm  selectedUser={player} setSelectedUser={setPlayer}  getTicketList={getTicketList} getUserInfo={getUserInfo} getWinningNumber={getWinningNumber}  />
        <OperationTable ticketList={ticketList}/>
        <WinningNumberTable winningNumberList={winningNumberList}/>
        </div>
      
 
    </>
    )
  }
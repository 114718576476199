
  
  import SelectInput from "../ui/SelectInput";
import { useEffect,useState } from "react";
  export default function OperationTable(props) {

    const [tickets,setTickets]=useState(props.ticketList)
    const [numberOfCurrency, setNumberOfCurrency] = useState(0);
    const [player,setPlayer]=useState(0);
    const [generatedTicket,setGeneratedTicket]=useState(0);
  const [roundNumber, setRoundNumber] = useState(0);

    function formatDate(inputDate) {
      const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}.${month}.${day} ${hours}:${minutes}`;
    }


    
  useEffect(() => {
    setTickets(props.ticketList);

    handleFieldChange("Result", numberOfCurrency);
    handleFieldChange("RoundId", roundNumber);
    handleFieldChange("PlayerId", player);
    handleFieldChange("GeneratedTicket", generatedTicket);
    console.log("generatedTicket",generatedTicket)
  }, [props.ticketList, numberOfCurrency, roundNumber,player,generatedTicket]);
  

  
  const handleFieldChange = (fieldName, value) => {

    let newResult = numberOfCurrency;
    let newPlayer = player;
    let newRound = roundNumber;
    let newGeneratedValue = generatedTicket
  
    // Itt beállítod a megfelelő értékeket a fieldName alapján
    switch (fieldName) {
   
      case "Result":
        newResult = value;
        break;
      case "RoundId":
        newRound = value;
        break;

        case "PlayerId":
          newPlayer = value;
          break;
          case "GeneratedTicket":
            newGeneratedValue = value;
            break;
    
      default:
        break;
    }
   
    getFilteredReports([
      { key: "Result", value: newResult },
      { key: "PlayerId", value: newPlayer },
      { key: "RoundId", value: newRound },
      { key: "GeneratedTicket", value: newGeneratedValue },
    ]);
  
    setPlayer(newPlayer)
    setNumberOfCurrency(newResult);
    setRoundNumber(newRound);
    setGeneratedTicket(newGeneratedValue)

  };


  const getFilteredReports = (filters) => {
    const filteredReports = applyFilters(props.ticketList, filters);
    setTickets(filteredReports);
   
  };

  const applyFilters = (reports, filters) => {
    return reports?.filter((rep) => {
      for (const filter of filters) {
        const { key, value } = filter;
        const reportValue = (rep[key]);

        if ((value !== 0  && value !== "Válassz.." )&& reportValue !== (value)) {
          return false;
        }
      }
      return true;
    });
  };


  const formFields = [
    {
      Fieldtype: "select",
      id: "generatedTicket",
      label: "Generált szelvény",
      value: generatedTicket,
      table: "generatedvalues",
      listType: { id: "generatedId", name: "generatedName" },
      onChange: (e) => {
        handleFieldChange("GeneratedTicket", e.value);
      },
    },
    {
      Fieldtype: "select",
      id: "player",
      label: "Játékos neve",
      value: player,
      table: "player",
      listType: { id: "playerId", name: "nickName" },
      onChange: (e) => {
        handleFieldChange("PlayerId", e.value);
      },
    },
    {
      Fieldtype: "select",
      id: "numberOfCurrency",
      label: "Találatok száma",
      value: numberOfCurrency,
      table: "result",
      listType: { id: "ResultId", name: "ResultName" },
      onChange: (e) => {
        handleFieldChange("Result", e.value);
      },
    },

    {
      Fieldtype: "select",
      id: "roundNumber",
      label: "Kör száma",
      value: roundNumber,
      table: "round",
      listType: { id: "RoundId", name: "RoundId" },
      onChange: (e) => {
        handleFieldChange("RoundId", e.value);
      },
    },
  ];

    let sumTotalValue = 0;
   let totalIncome = 0;
   let quantity = 0;

return(
  <div className="col-span-2 flex flex-col overflow-hidden bg-white shadow sm:rounded-lg ">
     <div className="flex items-center justify-between gap-2 px-3 py-2">
            <div className="sm:flex-auto py-6 item-center text-center">
            <p className="text-gray-500 font-bold italic">Szelvények </p>
            </div>
           
                  {formFields.map((field) => {
                      if (field.Fieldtype === "select") {
                        return (
                          <div className="relative basis-64 col">
                            <SelectInput
                              key={field.id}
                              onChange={field.onChange}
                              label={field.label}
                              value={field.id}
                              disabled={field.disabled}
                              id={field.id}
                              table={field.table}
                              listType={field.listType}
                            />
                          </div>
                        );
                      }
                    })}
               


            </div>

        <div className=" col h-full flex-col overflow-hidden bg-white p-2 shadow sm:rounded-lg">
          <div className="flex h-full overflow-hidden border-t border-gray-200">
            <div className="w-full overflow-x-auto ">
            <div className="inline-block min-w-full border-b border-gray-200 align-middle">
        <table className=" min-w-full divide-y divide-gray-300 shadow-xl ">
          <thead className="bg-gray-100">
            <tr>
            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Időpont
              </th>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Játékos
              </th>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Kör
              </th>

              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Szám 1
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Szám 2
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Szám 3
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Szám 4
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Szám 5
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Találat
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Nyeremény
              </th>
            
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 "> 
            {tickets?.map((person) =>{
              sumTotalValue+=person.resultValue;
              totalIncome+=500;
              quantity +=1;
              return(
                <tr key={person.TicketId} className={person.GeneratedTicket=='Y' ?`divide-y divide-gray-200 bg-yellow-100`: `divide-y divide-gray-200 bg-white`}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  font-medium text-gray-900 sm:pl-6">
                    {formatDate(person.createdDate)}
                  </td>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  font-medium text-gray-900 sm:pl-6">
                  {person.PlayerId ? person.nickName : "Generált"}
                  </td>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  font-medium text-gray-900 sm:pl-6">
                    {person.RoundId}.Kör
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">{person.num1}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">{person.num2}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">{person.num3}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">{person.num4}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">{person.num5}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">{person.Result!=null ? `${person.Result} db` : 'Húzás alatt..'}  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">{person.resultValue !=null? `${person.resultValue} Acse` :`Húzás alatt..`} </td>
              
                </tr>
              )
            }
             )}

          </tbody>
        </table>
<div className="px-3 py-3"> 
<p className="italic text-gray-700 font-bold py-3">Statisztikai adatok </p>
<table className="min-w-full divide-y divide-gray-300 shadow-xl">
                <thead className="bg-red-100">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Szelvényeből származó árbevétel
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Szelvényekre kifizetett összeg
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Szelvények mennyisége
                    </th>
                    
                  
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-600 italic">{totalIncome} Acse</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-600 italic">{sumTotalValue} Acse </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-600 italic">{quantity} db </td>
                </tbody>
                </table>
                </div>
    
        </div>
      </div>
    </div>
  </div>
</div>

)
  }
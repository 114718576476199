
import ChartComponent from "./ChartComponent";
import StatisticTable from "./StatisticTable";
import { useState } from "react";


export default function Tabs() {


  const [showJobInfo, setShowJobInfo] = useState(true);
  const [showMaterial, setShowMaterial] = useState(false);
  const [current, setCurrent] = useState("Gyártás");

  
  const tabs = [
    { id: 0, name: "Gyártás" },
    { id: 1, name: "Anyag" },
  ];




  const showCurrentPage = (name) => {
    if (name === "Gyártás") {
      setShowMaterial(false);
      return setShowJobInfo(true);
    }
    if (name === "Anyag") {
      setShowJobInfo(false);

      return setShowMaterial(true);
    }
  };
  const handleChange = (event) => {
    setCurrent(event.target.value);
  };


  return (
    <div>
        <div className="relative mt-3">
          <div className="">
            <div className="sm:hidden">
              <label htmlFor="current-tab" className="sr-only">
                Válassz egy menüpontot
              </label>
              <select
                id="current-tab"
                name="current-tab"
                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                onChange={handleChange}
                onClick={() => showCurrentPage(current)}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <nav className="-mb-px flex space-x-8">
                <a
                  onClick={() => {
                    setShowJobInfo(true);

                    setShowMaterial(false);
                  }}
                  className={`${
                    showJobInfo
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  } cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium`}
                  aria-current={showJobInfo ? "page" : undefined}
                >
                  Táblázat
                </a>

                <a
                  onClick={() => {
                    setShowMaterial(true);
                    setShowJobInfo(false);
                  }}
                  className={`${
                    showMaterial
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  } cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium`}
                
                  aria-current={showMaterial ? "page" : undefined}
                >
                  Statisztikai kimutatások
                </a>
              </nav>
            </div>
          </div>

  
        </div>
        {showJobInfo ? <StatisticTable /> : null}
          {showMaterial ? <ChartComponent /> : null}
       
      </div>
    
  );
}

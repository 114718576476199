import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import { useState,useEffect } from 'react';
import axios from 'axios';
// ...

const VISIBLE_FIELDS = ['Időpont', 'Játékos', 'Kör', 'Szam1', 'Szam2', 'Szam3', 'Szam4', 'Szam5', 'Találat', 'Nyeremény'];

function formatDate(inputDate) {
  const date = new Date(inputDate);

const year = date.getFullYear();
const month = String(date.getMonth() + 1).padStart(2, "0");
const day = String(date.getDate()).padStart(2, "0");
const hours = String(date.getHours()).padStart(2, "0");
const minutes = String(date.getMinutes()).padStart(2, "0");

return `${year}.${month}.${day} ${hours}:${minutes}`;
}


export default function StatisticTable(props) {

const [ticketList,setTicketList]=useState(null);

  const getTicketList = async () => {
    try {
      const requestData = {
        tables: ["ticket","player"],
        joins: ["ticket.PlayerId = player.playerId"],
        conditions: [],
        selectedColumns: ["*"],
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/getJoinedData`,
        requestData
      );
      console.log("result", response.data); // Itt kezelheti a választ

     
  
      setTicketList(response.data); // Ez fogja beállítani a rendezett adatokat az állapotban
    } catch (error) {
      console.error(error);
    }
  };


  
  const [summaHits,setSummaHits] = useState(null);
  const getSummaHits = async () => {
    try {
      const getSummaWithDraw = await fetch(`${process.env.REACT_APP_SERVER_URL}/getHitsSumma`);
  
      if (!getSummaWithDraw.ok) {
        throw new Error("Hiba történt a kérés során");
      }
  
      const response = await getSummaWithDraw.json(); // Válasz adatok elérése
      console.log("get summa hits ", response);
      setSummaHits(response.TotalResultValue);
      
  
    } catch (error) {
      console.error(error);
      // Kezeld a hibát itt
    }
  };


  const [maxWinNum,setmaxWinNum] = useState(null);
  const getMaxWinNum = async () => {
    try {
      const getSummaWithDraw = await fetch(`${process.env.REACT_APP_SERVER_URL}/getMaxWinValue`);
  
      if (!getSummaWithDraw.ok) {
        throw new Error("Hiba történt a kérés során");
      }
  
      const response = await getSummaWithDraw.json(); // Válasz adatok elérése
      console.log("get summa hits ", response);
      setmaxWinNum(response.TotalResultValue);
      
  
    } catch (error) {
      console.error(error);
      // Kezeld a hibát itt
    }
  };



  
  const [ticketSumNumber,setTicketSumNumber] = useState(null);
  const getTicketSummaNumber = async () => {
    try {
      const getSummaWithDraw = await fetch(`${process.env.REACT_APP_SERVER_URL}/getTicketNum`);
  
      if (!getSummaWithDraw.ok) {
        throw new Error("Hiba történt a kérés során");
      }
  
      const response = await getSummaWithDraw.json(); // Válasz adatok elérése
      console.log("get summa hits ", response);
      setTicketSumNumber(response.TotalResultValue);
      
  
    } catch (error) {
      console.error(error);
      // Kezeld a hibát itt
    }
  };

  const [maxWithdraw,setMaxWithdraw] = useState(null);
  const getWithDrawalSumma = async () => {
    try {
      const getSummaWithDraw = await fetch(`${process.env.REACT_APP_SERVER_URL}/getWithdrawSumma`);
  
      if (!getSummaWithDraw.ok) {
        throw new Error("Hiba történt a kérés során");
      }
  
      const response = await getSummaWithDraw.json(); // Válasz adatok elérése
      console.log("get withdraw summa", response);
        setMaxWithdraw(response.TotalResultValue);
      
  
    } catch (error) {
      console.error(error);
      // Kezeld a hibát itt
    }
  };


  const [maxTicketWorker,setMaxTicketWorker] = useState(null);
  const getMaxTicketWorker = async () => {
    try {
      const getSummaWithDraw = await fetch(`${process.env.REACT_APP_SERVER_URL}/getPlayerMaxTicket`);
  
      if (!getSummaWithDraw.ok) {
        throw new Error("Hiba történt a kérés során");
      }
  
      const response = await getSummaWithDraw.json(); // Válasz adatok elérése
      console.log("get withdraw summa", response);
      setMaxTicketWorker(response.TotalResultValue.nickName);
      
  
    } catch (error) {
      console.error(error);
      // Kezeld a hibát itt
    }
  };



  

useEffect(()=>{
  getTicketList();
  getSummaHits();
  getMaxWinNum();
  getTicketSummaNumber();
  getMaxTicketWorker();
  getWithDrawalSumma();
},[])


  // ... itt állítod be a data adatforrást

  // Adatok átalakítása a DataGrid számára
  const transformedData = React.useMemo(() => {
    // Az adatokból kinyerjük a megjelenítendő mezőket és átalakítjuk a megfelelő formátumba
    const rows = ticketList?.map((person) => ({
        id: person.TicketId,
        Időpont: formatDate(person.createdDate),
        Játékos: person.PlayerId ? person.nickName : "Generált",
        Kör: `${person.RoundId}.Kör`,
        Szam1: person.num1,
        Szam2: person.num2,
        Szam3: person.num3,
        Szam4: person.num4,
        Szam5: person.num5,
        Találat: person.Result != null ? `${person.Result} db` : 'Húzás alatt..',
        Nyeremény: person.resultValue != null ? `${person.resultValue} Acse` : 'Húzás alatt..',
      }));
    

    return rows || [];
  }, [ticketList]);

  const localizedTextsMap = {
    columnMenuUnsort: "Rendezés törlése",
    columnMenuSortAsc: "Növekvő sorrendbe rendezés",
    columnMenuSortDesc: "Csökkenő sorrendbe rendezés",
    columnMenuFilter: "Szűrő",
    columnMenuHideColumn: "Oszlop eltüntetése",
    columnMenuShowColumns: "Sor menedzselés"
  };

  return (
    <div className='bg-gray-100  '> 
 
    <div className='px-6 py-6'> 
     <p className=' text-gray-700 text-xl font-semibold italic'> Szelvények rendezése </p>
    </div>

      <div className='bg-white text-gray-900  shadow-xl' style={{ height: 600, width: '100%' }}>
 
    <DataGrid
      rows={transformedData}
      columns={VISIBLE_FIELDS.map((field) => ({
        field,
        headerName: field.charAt(0).toUpperCase() + field.slice(1), // Első betű nagybetűsítése
        flex: 1,
      }))}
      localeText={localizedTextsMap}
      options={{ pagination: false }}
  


    />
  </div>
<div className='py-6 shadow-xl '> 
<table className="min-w-full divide-y divide-gray-300 shadow-xl py-6">
                <thead className="bg-red-100">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Szelvényeből származó árbevétel
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Szelvényekre kifizetett összeg
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Szelvények mennyisége
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Legmagasabb nyeremény
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Legtöbb szelvény feladó neve
                    </th>
                    
                  
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-600 italic">{ticketSumNumber*500} Acse</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-600 italic">{maxWithdraw} Acse </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-600 italic">{ticketSumNumber} db </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-600 italic">{maxWinNum} Acse </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-600 italic">{maxTicketWorker} </td>
                </tbody>
                </table>
</div>
  

    </div>
    
  );
}
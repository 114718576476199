
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'

export function AddPlayerDialog(props) {
  


  const cancelButtonRef = useRef(null)

  const [nickName,setNickName]=useState(null);
    const [nicNameValid,setNickNameValid]=useState(false);
  
  const insertData = async () => {
    try {

   
      const tableName = "player";
        const id = "playerId"

        const insertObj={
            nickName: nickName,
            balance:10000,
            RoleId:1
        }
      // Fetch hívás a Node.js szerver felé
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/addItem`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tableName, id, data: insertObj }), // Az adatok JSON formátumban elküldve
        }
      );

      if (response.ok) {
        const result = await response.json(); // A szerver válaszának feldolgozása
        console.log("result", result);

        alert(`Sikeresen felvette  ${nickName} nevű játékost!`)
        //  setData(result);
      } else {
        console.error("Hiba történt a szerverrel való kommunikáció során.");
      }
    
    } catch (error) {
      console.error("Hiba történt:", error);
    }
    setNickName('');
    setNickNameValid(false);
  
    props.getPlayerList()
    props.setShow()
  };


    return (
        <Transition.Root show={props.show} as={Fragment}>
          <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.setShow}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
    
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Játékos hozzáadása
                        </Dialog.Title>
                        <div>
      
      <div className="mt-2">
        <input
          type="nickName"
          name="nickName"
          value={nickName}
          id="nickName"
          onChange={(e)=>{setNickName(e.target.value);  setNickNameValid(e.target.value !== ''); }}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Adja meg a játékhoz használt nevét"
        />
      </div>
    </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <button
                        type="button"
                        disabled={!nicNameValid}
                        className="inline-flex disabled:cursor-not-allowed disabled:opacity-50 w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                        onClick={()=>insertData()}
                      >
                        Hozzáadás
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={props.setShow}
                        ref={cancelButtonRef}
                      >
                        Mégsem
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )
}

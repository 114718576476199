import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import NavigationElements from "./NavigationElement";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
//import { ConfectioningForm } from "../report/ConfectioningForm";
//import { Settings } from "../settings";
//import { ExtrusionReport } from "../report/ExtrusionReport";
import axios from "axios";
import PlayerIndex from "../Player/PlayerIndex";
import GameIndex from "../Game/GameIndex";
import OperationIndex from "../Operator/OperationIndex";
import StatisticTable from "../Statistic/StatisticTable";
import Tabs from "../Statistic/Tabs";

export const Layout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

 

  const [selectedUser,setSelectedUser]=useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  let { userId } = useParams();

  // Ellenőrizd az aktuális útvonalat
  const PlayerIndexRoute = location && location.pathname === "/players" || location.pathname === '/';
  const statisticsRoute = location && location.pathname === "/statistics";

  const isOperationRoute = location && location.pathname === "/operator";
  


  const storedUserData = localStorage.getItem("userId");
  const initialUserState = storedUserData ? JSON.parse(storedUserData) : null;

  const [user, setUser] = useState(initialUserState);

  //const machineId = localStorage.getItem("machineId");
  const [activeMachineId, setActiveMachineId] = useState(null);

  console.log("activeMachineId", activeMachineId);

  console.log("Worker info", user);
  const getLastMachineActivities = async () => {
    try {
      const requestData = {
        tables: ["machineworker", "machine", "worker"],
        joins: [
          "machineworker.MachineId = machine.MachineId",
          "machineworker.WorkerId = worker.WorkerId",
        ],
        conditions: [`worker.WorkerNumber = ${user.WorkerNumber}`],
        selectedColumns: ["machineworker.*", "machine.*", "worker.*"],
      };

      const response = await axios.post(
        `${import.meta.env.VITE_REACT_APP_API_URL}/getJoinedData`,
        requestData
      );

      // Válasz elemeinek átalakítása a kívánt struktúrára
      const transformedData = response.data.map((item) => ({
        MachineId: item.MachineId,
        MachineNumber: item.MachineNumber,
        MahineName: item.MahineName,
        OperationId: item.OperationId,
      }));

      console.log("Transformed data:", transformedData);
      setSelectedMachine(transformedData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getLastMachineActivities();
    const machineId = localStorage.getItem("machineId");
    console.log("machineId", machineId);
    setActiveMachineId(machineId);
  }, []);

  useEffect(() => {
    getLastMachineActivities();
    const machineId = localStorage.getItem("machineId");
    console.log("machineId", machineId);
    setActiveMachineId(machineId);
  }, [user]);

  useEffect(() => {
    const machineId = localStorage.getItem("machineId");
    console.log("machineId", machineId);
    setActiveMachineId(machineId);
  }, [activeMachineId]);

  const [selectedMachine, setSelectedMachine] = useState(() => {
    const storedValue = localStorage.getItem("selectedMachine");
    return storedValue ? JSON.parse(storedValue) : [];
  });

  useEffect(() => setSidebarOpen(false), [location]);

  console.log("Selected machine", selectedMachine);

  return (
    <div className="h-screen">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Menü bezárása</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>

                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                  <div className="flex h-16 shrink-0 items-center">
                   
                  </div>
                  <nav className="flex flex-1 flex-col ">
                    <NavigationElements />
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Menü megnyitása</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>

        {/* Separator */}
        <div className="h-6 w-px bg-gray-900/10" aria-hidden="true" />
        <div className="absolute inset-0 left-16 right-64 flex items-center justify-center gap-x-4 overflow-auto">
       
        </div>
        <div className="absolute right-4 flex items-center">
  
        </div>
      </div>

     
      <main className="px-4 py-6 sm:px-6 lg:px-8 bg-gray-100"
       >
      {PlayerIndexRoute && (
          <PlayerIndex
           setSelectedUser={setSelectedUser}
          />
        )}

{statisticsRoute && (
          <Tabs
          />
        )}

{isOperationRoute && (
          <OperationIndex
          />
        )}


{location.pathname.includes("/game/") && (
  <GameIndex
    selectedUser={selectedUser}
    setSelectedUser={setSelectedUser}
  />
)}
        </main>

    </div>
  );
};



  export default function WinningNumberTable(props) {

  

    function formatDate(inputDate) {
      const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}.${month}.${day} ${hours}:${minutes}`;
    }

return(

<div className="col flex flex-col overflow-hidden bg-white shadow sm:rounded-lg ">
            <div className="flex items-center justify-between gap-2 px-3 py-2">
            <div className="sm:flex-auto py-6 item-center text-center">
            <p className="text-gray-500 font-bold italic">Húzott számok </p>
            </div>
            </div>

        <div className=" col  h-full flex-col overflow-hidden bg-white p-2 shadow sm:rounded-lg">
          <div className="flex h-full overflow-hidden border-t border-gray-200">
            <div className="w-full overflow-x-auto ">
            <div className="inline-block min-w-full border-b border-gray-200 align-middle">
        <table className="min-w-full divide-y divide-gray-300 ">
          <thead className="bg-gray-100 ">
            <tr>
        
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Kör
              </th>

              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Szám 1
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Szám 2
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Szám 3
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Szám 4
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Szám 5
              </th>
         
            
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {props.winningNumberList?.map((person) => (
              <tr key={person?.WinningNumberId}>
         
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  font-medium text-gray-900 sm:pl-6">
                  {person?.RoundId}.kör
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{person?.num1}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{person?.num2}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{person?.num3}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{person?.num4}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{person?.num5}</td>
          
            
              </tr>
            ))}
             <tr className="sticky bottom-0 bg-yellow-200 italic">
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
               Aktuális
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-500 text-center">
                {props.winningNumberList?.slice(-1)[0]?.num1}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-500 text-center">
                {props.winningNumberList?.slice(-1)[0]?.num2} 
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-500 text-center">
                {props.winningNumberList?.slice(-1)[0]?.num3} 
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-500 text-center">
                {props.winningNumberList?.slice(-1)[0]?.num4} 
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-500 text-center">
                {props.winningNumberList?.slice(-1)[0]?.num5} 
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </div>
  </div>
</div>

)
  }

import './input.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from './Navigation/Layout';
import PlayerIndex from './Player/PlayerIndex';
import GameIndex from './Game/GameIndex';
import OperationIndex from './Operator/OperationIndex';
import StatisticTable from './Statistic/StatisticTable';
import Tabs from './Statistic/Tabs';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
        <Route path="players" element={<PlayerIndex />} />
        <Route path="operator" element={<OperationIndex />} />
        <Route path="statistics" element={<Tabs />} />
        <Route path="/game/:userId" element={<GameIndex />} />
        
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import { useNavigate,useParams } from "react-router-dom"
import axios from "axios";
import { useState,useEffect } from "react";
import { AddPlayerDialog } from "./AddPlayerDialog";

  export default function PlayerIndex(props) {
    const navigate = useNavigate();


    //let { playerId } = useParams();
    const [playerList,setPlayerList]=useState(null);
const [addPlayerDialogVisible,setAddPlayerDialogVisible]=useState(false);

    const getPlayerList = async () => {
     // console.log("props.orderInfo", props.orderInfo);
      try {
        const requestData = {
          tables: ["player"],
          joins: [],
          conditions: [`RoleId = 1`],
          selectedColumns: ["*"],
        };
  
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/getJoinedData`,
          requestData
        );
        console.log("result", response.data); // Itt kezelheti a választ
        setPlayerList(response.data);
      } catch (error) {
        console.error(error);
      }
    };
  
    useEffect(() => {
      getPlayerList();
    }, []);
  
    useEffect(() => {
      getPlayerList();
    }, [props]);

    return (

         
      <div className="h-screen"
    
      >

<div className="px-3 py-3">
  <p className="italic text-gray-800 font-bold  text-xl text-center">   Válassza ki a játékost, vagy adjon hozzá egy újat a listához! </p>
</div>
      
        <div className="py-3 px-3 "> 
        <button
                  type="button"
                  onClick={()=>setAddPlayerDialogVisible(true)}
    
                  className="rounded-md disabled:cursor-not-allowed disabled:opacity-50 bg-indigo-600 px-4 py-2 text-md font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                   <div className="flex items-center align-center"> 
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="-ml-0.5 h-5 w-5">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>
Játékos hozzáadása
</div>
                </button>
                </div>
          



                <ul
            role="list"
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-6 justify-center"
          >
            
          

          
           
            {playerList?.map((person) => (
                <div   className="col-span-1 items-center ">
               
              <li  onClick={() => {navigate(`/game/${person.playerId}`); props.setSelectedUser(person); }} key={person.playerId} 
               className="flex flex-col items-center rounded-2xl bg-emerald-500 px-8 py-10 hover:bg-emerald-400 hover:cursor-pointer shadow-xl">
              
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-24 h-24">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
</svg>


                <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-800 italic">{person.nickName}</h3>
                <p className="text-sm leading-6 font-semibold text-gray-800 italic">{person.balance} ACSE</p>
               
              </li>
              </div>
            ))}
          </ul>
   <AddPlayerDialog show={addPlayerDialogVisible} setShow={()=>setAddPlayerDialogVisible(false)} getPlayerList={getPlayerList}/>

       </div>
        

    )
  }
import InputField from "../ui/inputField";
import { useState, useEffect } from "react";
import axios from "axios";
export default function OperationForm(props) {
  const [generateNumber, setGenerateNumber] = useState(0);
  const [winNumbers, setWinNumbers] = useState(null);
  const [lastRoundTickets, setLastRoundTickets] = useState(null);

  const[generatedNumberValid,setGeneratedNumberValid] = useState(false);

  useEffect(() => {
    getLastRoundTickets();
    console.log("lastRoundTickets", lastRoundTickets);
  }, []);

  useEffect(() => {
    getLastRoundTickets();
  }, [props]);

  const updateBalance = async () => {
    console.log("generateNumber", generateNumber);
    const updateData = {
      tableName: "player",
      updates: {
        balance: props.selectedUser.balance + 500 * generateNumber,
      },
      whereClause: {
        condition: `RoleId = 2`,
      },
    };
    console.log("new balance data", updateData);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/updateTable`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateData),
        }
      );

      if (response.ok) {
        console.log("Balance -500 change successfully.");
        props.setSelectedUser({
          ...props.selectedUser,
          balance: props.selectedUser.balance + 500 * generateNumber,
        });
        // props.setJobStatus(OperationItemId, num);
      } else {
        console.error("Error changing status:", response.statusText);
      }
    } catch (error) {
      console.error("Error changing status:", error);
    }
  };

 
function generateRandomNumbers() {
    const numbers = new Set();
  
    while (numbers.size < 5) {
      const randomNumber = Math.floor(Math.random() * 39) + 1;
      numbers.add(randomNumber);
    }
  
    return Array.from(numbers);
  }
  

  const updateObject = async (winNumId, id) => {
    const updateData = {
      tableName: "round",
      updates: {
        WinningNumberId: winNumId,
      },
      whereClause: {
        condition: `RoundId = ${id}`,
      },
    };
    console.log("Information data", updateData);
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/updateTable`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateData),
        }
      );
  
      if (response.ok) {
        console.log("Round update change successfully.");
      } else {
        console.error("Error changing status:", response.statusText);
      }
    } catch (error) {
      console.error("Error changing status:", error);
    }
  };

 

  
  const generateRound = async () => {
    try {
        const lastRoundTickets = await getLastRoundTickets();
        console.log("lastRoundTickets", lastRoundTickets);

        const lastRoundIdResponse = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/getLastRoundId`
        );

        if (!lastRoundIdResponse.ok) {
            throw new Error("Last Round ID fetch failed");
        }

        let LastRoundId;

        try {
            const { LastRoundId: responseLastRoundId } = await lastRoundIdResponse.json();
            LastRoundId = responseLastRoundId;
        } catch (jsonError) {
            console.error('Hiba a JSON formátumú válasz feldolgozásakor:', jsonError);
            LastRoundId = null; // vagy más kezdeti érték
        }

        if (LastRoundId === null || LastRoundId === '' || LastRoundId === 0) {
            console.log("Last round ID is null");
            const id = await returnNewRoundId();
            console.log("resCreateNewRound", id);
            LastRoundId = id;
        }

        const numbers = generateRandomNumbers();
        setWinNumbers(numbers);

        await updateTicketResults(lastRoundTickets, numbers);

        const insertObj = {
            num1: numbers[0],
            num2: numbers[1],
            num3: numbers[2],
            num4: numbers[3],
            num5: numbers[4],
            RoundId: LastRoundId,
        };

        const tableName = "winningnumber";
        const id = "winningNumberId";

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/addItem`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ tableName, id, data: insertObj }),
            }
        );

        if (response.ok) {
            const result = await response.json();
            console.log("result winning number", result);
            updateObject(result.insertedId, LastRoundId);
            await createNewRound();
            alert('Sikeresen kihúzta a győztes számokat, új kört indított!');
        } else {
            console.error("Hiba történt a szerverrel való kommunikáció során.");
        }
    } catch (error) {
        console.error("Hiba történt:", error);
    }

    await props.getTicketList();
    await props.getWinningNumber();
    await props.getUserInfo();
};

  const [resCreateNewRound,setResCreateNewRound]= useState(null);
  const createNewRound = async () => {
    try {
      const insertObj = {
        RoundId: null,
        WinningNumberId: null,
      };
  
      const tableName = "round";
      const id = "RoundId";
  
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/addItem`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tableName, id, data: insertObj }),
        }
      );
  
      if (response.ok) {
        const result = await response.json();
        console.log("New round created successfully!", result);
        setResCreateNewRound(result.insertedId)
      } else {
        console.error("Hiba történt a szerverrel való kommunikáció során.");
      }
    } catch (error) {
      console.error("Hiba történt:", error);
    }
  };


  const returnNewRoundId = async () => {
    try {
      const insertObj = {
        RoundId: null,
        WinningNumberId: null,
      };
  
      const tableName = "round";
      const id = "RoundId";
  
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/addItem`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tableName, id, data: insertObj }),
        }
      );
  
      if (response.ok) {
        const result = await response.json();
        console.log("New round created successfully!", result);
        return result.insertedId
      } else {
        console.error("Hiba történt a szerverrel való kommunikáció során.");
      }
    } catch (error) {
      console.error("Hiba történt:", error);
    }
  };


  
  const insertData = async () => {
    try {
        const lastRoundIdResponse = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/getLastRoundId`
        );

        if (lastRoundIdResponse.ok) {
            let LastRoundId;

            try {
                const { LastRoundId: responseLastRoundId } = await lastRoundIdResponse.json();
                LastRoundId = responseLastRoundId;
            } catch (jsonError) {
                console.error('Hiba a JSON formátumú válasz feldolgozásakor:', jsonError);
                LastRoundId = null; // vagy más kezdeti érték
            }

            if (LastRoundId === null || LastRoundId === '' || LastRoundId === 0) {
                console.log("Last round ID is null");
                const id = await returnNewRoundId();
                console.log("resCreateNewRound", id);
                LastRoundId = id;
            }

            for (let i = 0; i < generateNumber; i++) {
                const numbers = generateRandomNumbers();

                const insertObj = {
                    num1: numbers[0],
                    num2: numbers[1],
                    num3: numbers[2],
                    num4: numbers[3],
                    num5: numbers[4],
                    RoundId: LastRoundId,
                    createdDate: new Date(),
                    GeneratedTicket: "Y",
                };

                const tableName = "ticket";

                const response = await fetch(
                    `${process.env.REACT_APP_SERVER_URL}/addItem`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ tableName, data: insertObj }),
                    }
                );

                if (response.ok) {
                    const result = await response.json();
                    alert(`Sikeresen generált ${generateNumber}.db szelvényt!`);
                    console.log("result", result);
                } else {
                    console.error("Hiba történt a szerverrel való kommunikáció során.");
                }
            }
        }
    } catch (error) {
        console.error("Hiba történt:", error);
    }

    updateBalance();
    props.getTicketList();
    props.getWinningNumber();
    props.getUserInfo();
    setGenerateNumber('');
    setGeneratedNumberValid(false);
};

  
  const updateTicketResults = async (tickets, winningNumbers) => {
    try {
     
      for (const ticket of tickets) {
        const commonElements = Object.values(ticket)
          .filter((value) => winningNumbers.includes(value)).length;
        console.log("commonElements",commonElements)
        let increment = 0;
      switch (commonElements) {
        case 2:
          increment = 500;
          break;
        case 3:
          increment = 1000;
          break;
        case 4:
          increment = 2000;
          break;
        case 5:
          increment = 3000;
          break;
        default:
          increment = 0;
          break;
      }
        const updateData = {
          tableName: "ticket",
          updates: {
            Result: commonElements,
            ResultValue: increment
          },
          whereClause: {
            condition: `TicketId = ${ticket.TicketId}`,
          },
        };
  
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/updateTable`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updateData),
          }
        );
  
        if (response.ok) {
          console.log(`Ticket ${ticket.TicketId} updated with result ${commonElements}`);
          updatePlayerBalance(commonElements, ticket.PlayerId);
          console.log("Ticket information of updateing balance",ticket);
          if(ticket.GeneratedTicket==='N'){
            updateOperatiorBalance(commonElements);
          }
          
        } else {
          console.error(`Error updating ticket ${ticket.TicketId}`);
        }
      }
    } catch (error) {
      console.error("Error updating tickets:", error);
    }
  };

  const updatePlayerBalance = async (commonElements, playerId) => {
    try {
      let increment = 0;
      switch (commonElements) {
        case 2:
          increment = 500;
          break;
        case 3:
          increment = 1000;
          break;
        case 4:
          increment = 2000;
          break;
        case 5:
          increment = 3000;
          break;
        default:
          increment = 0;
          break;
      }
  
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/updatePlayerBalance`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ playerId, increment }), // A PlayerId és az increment érték elküldése
        }
      );
  
      if (response.ok) {
        console.log(`PlayerId ${playerId} balance updated with increment ${increment}`);
      } else {
        console.error(`Error updating balance for PlayerId ${playerId}`);
      }
    } catch (error) {
      console.error("Error updating player balance:", error);
    }
}


const updateOperatiorBalance = async (commonElements) => {
  try {
    let decrement = 0;
    switch (commonElements) {
      case 2:
        decrement = 500;
        break;
      case 3:
        decrement = 1000;
        break;
      case 4:
        decrement = 2000;
        break;
      case 5:
        decrement = 3000;
        break;
      default:
        decrement = 0;
        break;
    }

    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/updateOperatorBalance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({  decrement }), // A PlayerId és az decrement érték elküldése
      }
    );




    if (response.ok) {
      props.setSelectedUser({
        ...props.selectedUser,
        balance: props.selectedUser.balance -decrement,
      });
  
      console.log(`Operator  balance updated with decrement ${decrement}`);
    } else {
      console.error(`Error updating balance for Operator`);
    }
  } catch (error) {
    console.error("Error updating player balance:", error);
  }
}


const getLastRoundTickets = async () => {
  try {
      const lastRoundIdResponse = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/getLastRoundId`
      );

      let LastRoundId;

      try {
          const { LastRoundId: responseLastRoundId } = await lastRoundIdResponse.json();
          LastRoundId = responseLastRoundId;
      } catch (jsonError) {
          console.error('Hiba a JSON formátumú válasz feldolgozásakor:', jsonError);
          return [];
      }

      const requestData = {
          tables: ["ticket"],
          joins: [],
          conditions: [`RoundId=${LastRoundId}`],
          selectedColumns: ["*"],
      };

      const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/getJoinedData`,
          requestData
      );

      return response.data;
  } catch (error) {
      console.error(error);
      return [];
  }
};




  const handleResetGame = async () => {
    try {
      const resetGameValue = await fetch(`${process.env.REACT_APP_SERVER_URL}/handleResetGame`, {
        method: 'POST',
      });
  
      if (!resetGameValue.ok) {
        throw new Error("Failed to reset the game.");
      }
  
      const data = await resetGameValue.json();
      console.log(data.message); // Successful reset message
      alert("Sikeresen újra indította a játékot!")
      // Ide jöhet más logika vagy állapotfrissítés
    } catch (error) {
      console.error(error);
      // Kezeld a hibát itt
    }

    props.getTicketList();
    props.getWinningNumber();
  };


  const handleNewGame = async () => {
    try {
      const resetGameValue = await fetch(`${process.env.REACT_APP_SERVER_URL}/newGame`, {
        method: 'POST',
      });
  
      if (!resetGameValue.ok) {
        throw new Error("Failed to reset the game.");
      }
  
      const data = await resetGameValue.json();
      console.log(data.message); // Successful reset message
      alert("Sikeresen újra indította a játékot, lenullázta az üzemeltető-t, és a játokos kaptak 10.000 ACSE-t!")
      // Ide jöhet más logika vagy állapotfrissítés
    } catch (error) {
      console.error(error);
      // Kezeld a hibát itt
    }

    props.getTicketList();
    props.getUserInfo();
    props.getWinningNumber();
  };


  return (
    <div>
        <div className=""> 
      <form
        action="#"
        method="POST"
        className="flex flex-col h-fit overflow-x-auto bg-white shadow sm:rounded-lg"
      >
        <div className="flex justify-between text-center text-lg  font-semibold text-gray-900 px-3 py-5">
          <p>Üzemeltető: {props.selectedUser?.nickName}</p>
          <p className="sm:text-right">
            Egyenleg: {props.selectedUser?.balance} ACSE
          </p>
        </div>

        <div className="flex  flex-col  gap-6  border-t border-gray-200 px-3 py-9">
          <div className="grid grid-cols-3 gap-2  xl:grid-cols-4">
            <div className="relative col-span-1 md:col-span-2 lg:col-span-3 basis-64 border-b py-3 xl:col-span-4">
              <p className="py-4 font-semibold text-gray-900 italic ">
                Szelvények generálása, húzás indítása{" "}
              </p>
              <div className="flex">
                <InputField
                  id="reportIntervall"
                  label="Mennyiség"
                  type="number"
                  min={1}
                  max={200}
                  disabled={false}
                  value={generateNumber}
                  onChange={(e) => {
                    setGenerateNumber(e.target.value);
                    setGeneratedNumberValid(e.target.validity.valid)
                  }}
                />
              </div>

              <div className="py-3 flex justify-between items-center">
                <button
                  type="button"
                  onClick={insertData}
                  disabled={!generatedNumberValid}
                  className="rounded-md disabled:cursor-not-allowed disabled:opacity-50 bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Generálás
                </button>

                <button
                  type="button"
                  onClick={generateRound}
                  className="rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                  Húzás elindítása
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>





     
    </div>


    <div className="py-6"> 

<form
        action="#"
        method="POST"
        className="flex flex-col h-fit overflow-x-auto bg-white shadow sm:rounded-lg py-4"
      >
       

        <div className="flex  flex-col  gap-6  border-gray-200 px-3">
          <div className="grid grid-cols-3 gap-2  xl:grid-cols-4">
            <div className="relative col-span-1 md:col-span-2 lg:col-span-3 basis-64 py-3 xl:col-span-4">
              <p className="py-4 font-semibold text-gray-900 italic ">
                Játékkezelő felület. 
              </p>
            

              <div className="py-3 flex justify-between items-center">
              
                <button
                  type="button"
                  onClick={handleResetGame}
                  className="rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                >
                  Új játék indítása
                </button>

           
              
                <button
                  type="button"
                  onClick={handleNewGame}
                  className="rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                >
                       <div className="flex items-center align-center ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
</svg>
                 

                  Minden érték visszaállítása
                  </div>
                </button>
                
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  

    </div>
    
  );
}

import GameTable from "./GameTable"
import GameForm from "./GameForm"
import { useState,useEffect } from "react"
import { useParams } from "react-router-dom";

import axios from "axios"

  export default function GameIndex(props) {

    const { userId } = useParams();

    const [player,setPlayer]=useState(null);
    const [ticketList,setTicketList]=useState(null);

    
    const getUserInfo = async () => {
      try {
        const requestData = {
          tables: ["player"],
          joins: [],
          conditions: [`playerId = ${userId}`],
          selectedColumns: ["*"],
        };
  
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/getJoinedData`,
          requestData
        );
        console.log("result player", response.data); // Itt kezelheti a választ
        setPlayer(response.data[0]);
      } catch (error) {
        console.error(error);
      }
    };

    const getTicketList = async () => {
      try {
        const requestData = {
          tables: ["ticket"],
          joins: [],
          conditions: [`playerId = ${userId}`],
          selectedColumns: ["*"],
        };
  
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/getJoinedData`,
          requestData
        );
        console.log("result", response.data); // Itt kezelheti a választ
        setTicketList(response.data);
      } catch (error) {
        console.error(error);
      }
    };
  
    useEffect(() => {
      getTicketList();
      getUserInfo();
    }, []);
  
    useEffect(() => {
      getTicketList();
      getUserInfo();
    }, [props]);

    useEffect(() => {
      if (userId) {
        getTicketList();
        getUserInfo();
      }
    }, [userId]);


    return (
      <>
    
   <div 
   className="fixed inset-0 top-16 grid grid-cols-3 grid-rows-[auto_1fr] 
   gap-4 bg-gray-100 p-4"
   style={{
    backgroundImage: 'url("/bg33333.png")',
    backgroundSize: 'cover', // A háttérkép mérete a konténer méretéhez igazítva
    backgroundRepeat: 'no-repeat', // Ne ismételje meg a háttérképet
  }}
   >
  
  
       <GameForm selectedUser={player} setSelectedUser={setPlayer}  getTicketList={getTicketList} />
        <GameTable ticketList={ticketList}/>
      
    </div>
    </>
    )
  }

  
  import { useEffect,useState } from "react";
  

  export default function GameTable(props) {

    function formatDate(inputDate) {
      const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}.${month}.${day} ${hours}:${minutes}`;
    }

    const totalResultValue = props.ticketList?.reduce(
      (acc, person) => (acc += person.resultValue || 0),
      0
    );

    const sortByResult=
    [
      {id:1, value:"Alapértelmezett"},
      {id:2, value:"Növekvő"},
      {id:3, value:"Csökkenő"},
    ]

    
  const [selectedSort, setSelectedSort] = useState(1);

  const [sortedTickets, setSortedTickets] = useState(props.ticketList || []);

  const handleSortChange = (e) => {
    setSortedTickets([]);  // HA EZ A SOR BENNE VAN, AKKOR JÓ A RENDEZÉS, VISZONT A KÉPERNYŐ VILLAN EGYET ARRA A PILLANATRA, AMIKOR LENULLÁZOM A TÁBLÁZATOT
    setSelectedSort(parseInt(e.target.value));
  };

  // Rendezés a kiválasztott sorrend alapján
  useEffect(() => {
    console.log("props.ticketList",props.ticketList)
  
    if (selectedSort === 2) {
      const sortedList = props.ticketList?.sort((a, b) => a.Result - b.Result);
      setSortedTickets(sortedList);
    } 
    else if (selectedSort === 1) {
      setSortedTickets(props.ticketList);
    }
    else if (selectedSort === 3) {
      const sortedList = props.ticketList?.sort((a, b) => b.Result - a.Result);
      setSortedTickets(sortedList);
    }
  }, [selectedSort, props.ticketList]);


  
return(
<div className="flex  flex-col col-span-2 overflow-hidden bg-white shadow sm:rounded-lg w-full h-[88vh]">
<div className="flex items-center justify-between gap-2 px-3 py-3">

<div className="relative basis-64 px-8">
<p className="text-gray-500 text-lg font-bold italic"> Szelvények listája  </p>
</div>
 
  <div className="relative basis-64">
                    <label
                      htmlFor="factory"
                      className="absolute -top-2 left-2 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900"
                    >
                      Találatok száma 
                    </label>
                    <select
                      id="sortByResult"
                      name="sortByResult"
                      value={selectedSort}
                      onChange={handleSortChange}
                      className={
                        "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      }
                    >
                 {sortByResult.map((w) => (
                        <option key={w.id} value={w.id}>
                          {w.value}
                        </option>
                      ))} 
                    </select>
                  </div>


              

              
</div>
<div className=" col  h-full flex-col overflow-hidden bg-white p-2 shadow sm:rounded-lg">
          <div className="flex h-full overflow-hidden border-t border-gray-200">
            <div className="w-full overflow-x-auto ">
            <div className="inline-block min-w-full border-b border-gray-200 align-middle ">
        <table className="min-w-full divide-y bg-yellow-100 divide-gray-300  ">
          <thead className="bg-green-300">
            <tr>
            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Időpont
              </th>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Kör
              </th>

              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Szám 1
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Szám 2
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Szám 3
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Szám 4
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Szám 5
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Találat
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Nyeremény
              </th>
            
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-green-100">
            {sortedTickets?.map((person) => (
              <tr key={person.num4}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  font-medium text-gray-900 sm:pl-6">
                  {formatDate(person.createdDate)}
                </td>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  font-medium text-gray-900 sm:pl-6">
                  {person.RoundId}.kör
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">{person.num1}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">{person.num2}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">{person.num3}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">{person.num4}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">{person.num5}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">{person.Result !=null ? `${person.Result}db` : 'Hamarosan..'}  </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">{person.resultValue !=null ? `${person.resultValue} Acse` :`Hamarosan..`} </td>
            
              </tr>
            ))}


          </tbody>
        </table>
      </div>

      <div>
        
      </div>
    </div>
  </div>
</div>

<tr className="bg-yellow-200 sticky bottom-0 shadow-2xl py-2 ">
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  font-medium text-gray-900 sm:pl-6">
                 Össz. nyeremény: 
                </td>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  font-medium text-gray-900 sm:pl-6">
                {totalResultValue} Acse 
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center"> {}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center"> {}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center"> {}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center"> {}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center"> {}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center"> {} </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900"> {} </td>
            
              </tr>
</div>
)
  }
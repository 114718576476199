import InputField from "../ui/inputField"
import { useState } from "react";
export default function GameForm(props) {


    const [num1, setNum1] = useState(null);
    const [num2,setNum2] = useState(null);
    const [num3, setNum3] = useState(null);
    const [num4,setNum4] = useState(null);
    const [num5,setNum5] = useState(null);

    const [isFormFilled, setIsFormFilled] = useState({
      num1: false,
      num2: false,
      num3: false,
      num4: false,
      num5: false,
    });


    const updateBalance = async () => {

        const updateData = {
          tableName: "player",
          updates: {
            balance: props.selectedUser.balance-500,
          },
          whereClause: {
            condition: `playerId = ${props.selectedUser.playerId}`,
          },
        };
        console.log("Information data", updateData);
    
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/updateTable`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(updateData),
            }
          );
    
          if (response.ok) {
            console.log("Balance -500 change successfully.");
            props.setSelectedUser({...props.selectedUser, balance: props.selectedUser.balance - 500})
            // props.setJobStatus(OperationItemId, num);
          } else {
            console.error("Error changing status:", response.statusText);
          }
        } catch (error) {
          console.error("Error changing status:", error);
        }
      };

      const updateOperatorBalance = async () => {
       
    
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/addTicketPriceToOperator`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
    
          if (response.ok) {
            console.log("Balance -500 change successfully.");
            props.setSelectedUser({
              ...props.selectedUser,
              balance: props.selectedUser.balance - 500 ,
            });
            // props.setJobStatus(OperationItemId, num);
          } else {
            console.error("Error changing status:", response.statusText);
          }
        } catch (error) {
          console.error("Error changing status:", error);
        }
      };



      const returnNewRoundId = async () => {
        try {
          const insertObj = {
            RoundId: null,
            WinningNumberId: null,
          };
      
          const tableName = "round";
          const id = "RoundId";
      
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/addItem`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ tableName, id, data: insertObj }),
            }
          );
      
          if (response.ok) {
            const result = await response.json();
            console.log("New round created successfully!", result);
            return result.insertedId
          } else {
            console.error("Hiba történt a szerverrel való kommunikáció során.");
          }
        } catch (error) {
          console.error("Hiba történt:", error);
        }
      };
    
   



      const insertData = async () => {
        try {
            const numbers = [num1, num2, num3, num4, num5];
            const validRange = numbers.every(num => num >= 1 && num <= 39);
            const allUnique = new Set(numbers).size === 5;
            const enoughBalance = props.selectedUser.balance >= 500;
    
            if (!validRange) {
                alert('A számoknak 1 és 39 közé kell esniük!');
                return;
            }
    
            if (!allUnique) {
                alert('Minden számnak különbözőnek kell lennie!');
                return;
            }
    
            if (!enoughBalance) {
                alert('Sajnáljuk, de nincs elég egyenlege a játék megtételéhez!');
                return;
            }
    
            const lastRoundIdResponse = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/getLastRoundId`
            );
    
            if (lastRoundIdResponse.ok) {
                let LastRoundId={};
    
                try {
                    const data = await lastRoundIdResponse.json();
                    LastRoundId = data.LastRoundId;
                } catch (jsonError) {
                    console.error('Hiba a JSON formátumú válasz feldolgozásakor:', jsonError);
                    // Ha nem sikerült JSON formátumra alakítani, beállítjuk a LastRoundId-t null-ra vagy másra
                    LastRoundId = null; // vagy lehet más kezdeti érték is
                }
    
                if (LastRoundId === null || LastRoundId === '' || LastRoundId === 0) {
                    console.log("Last round ID is null");
                    const id = await returnNewRoundId();
                    console.log("resCreateNewRound", id);
                    LastRoundId = id;
                }
                console.log("Last Round Id:", LastRoundId);
    
                const insertObj = {
                    num1,
                    num2,
                    num3,
                    num4,
                    num5,
                    PlayerId: props.selectedUser.playerId,
                    RoundId: LastRoundId,
                    createdDate: new Date(),
                    GeneratedTicket: "N"
                };
    
                const tableName = "ticket";
                const id = "TicketId";
    
                const response = await fetch(
                    `${process.env.REACT_APP_SERVER_URL}/addItem`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ tableName, id, data: insertObj }),
                    }
                );
    
                if (response.ok) {
                    const result = await response.json();
                    console.log("result", result);
                    await updateBalance();
                    await updateOperatorBalance();
                    alert(`Sikeresen regisztrálta a szelvényét a ${LastRoundId}.fordulóra!`);
                } else {
                    console.error("Hiba történt a szerverrel való kommunikáció során.");
                }
            }
        } catch (error) {
            console.error("Hiba történt:", error);
        }
    
        setNum1('');
        setNum2('');
        setNum3('');
        setNum4('');
        setNum5('');
        setIsFormFilled({
            num1: false,
            num2: false,
            num3: false,
            num4: false,
            num5: false,
        });
        props.getTicketList();
    };
    


  
    return(
        <div className="h-fit" >
    <form action="#" method="POST" className="flex min-h-full  flex-col overflow-x-auto bg-white shadow sm:rounded-lg">
       
    <div className="flex items-center justify-between gap-2 px-3 py-2">

<div className="relative basis-64 px-4">
<p className="text-gray-700 text-md font-bold">  Játékos: {props.selectedUser?.nickName} </p>
</div>
<div className="flex items-center justify-between gap-2 px-3 py-2">

<div className="relative basis-64 ">
<p className="text-gray-700 text-md font-bold px-4">{props.selectedUser?.balance} ACSE  </p>
</div>
</div>
</div>
   

    <div className="flex  flex-col  gap-6  border-t border-gray-200 px-3 py-9">
            <div className="grid grid-cols-3 gap-2  xl:grid-cols-4">


                
              <InputField
                id="reportIntervall"
                label="Szám 1"
                type="number"
                min={1}
                max={39}
                disabled={false}
                value={num1}
                onChange={(e) => {
                    setNum1( e.target.value);
                    setIsFormFilled({ ...isFormFilled, num1: e.target.value !== '' });
                  //setItemNameValid(e.target.validity.valid)
                }}
              />
                   <InputField
                id="reportIntervall"
                label="Szám 2"
                type="number"
                min={1}
                max={39}
                disabled={false}
                value={num2}
                onChange={(e) => {
                    setNum2( e.target.value);
                    setIsFormFilled({ ...isFormFilled, num2: e.target.value !== '' });
                  //setItemNameValid(e.target.validity.valid)
                }}
              />
                   <InputField
                id="reportIntervall"
                label="Szám 3"
                type="number"
                min={1}
                max={39}
                disabled={false}
                value={num3}
                onChange={(e) => {
                    setNum3( e.target.value);
                  //setItemNameValid(e.target.validity.valid)
                  setIsFormFilled({ ...isFormFilled, num3: e.target.value !== '' });
                }}
              />
                   <InputField
                id="reportIntervall"
                label="Szám 4"
                type="number"
                min={1}
                max={39}
                disabled={false}
                value={num4}
                onChange={(e) => {
                    setNum4( e.target.value);
                    setIsFormFilled({ ...isFormFilled, num4: e.target.value !== '' });
                  //setItemNameValid(e.target.validity.valid)
                }}
              />
                   <InputField
                id="reportIntervall"
                label="Szám 5"
                type="number"
                min={1}
                max={39}
                disabled={false}
                value={num5}
                onChange={(e) => {
                    setNum5( e.target.value);
                    setIsFormFilled({ ...isFormFilled, num5: e.target.value !== '' });
                  //setItemNameValid(e.target.validity.valid)
                }}
              />
  <div>
       
        </div>
        
                    </div>
            </div>
            <div className="py-2 px-6"> 
        
            <button
            type="button"
            disabled={
              !(
                isFormFilled.num1 &&
                isFormFilled.num2 &&
                isFormFilled.num3 &&
                isFormFilled.num4 &&
                isFormFilled.num5
              )
            }
            onClick={insertData}
            className="flex justify-center disabled:cursor-not-allowed disabled:opacity-50 rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
            Játék megtétele
        </button>
            
        </div>

      
    </form>
    </div>
    )
}
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import { useState,useEffect } from 'react';
import axios from 'axios';
import { Bar,Pie } from 'react-chartjs-2';
// ...
import Chart from 'chart.js/auto'; 

const VISIBLE_FIELDS = ['Időpont', 'Játékos', 'Kör', 'Szam1', 'Szam2', 'Szam3', 'Szam4', 'Szam5', 'Találat', 'Nyeremény'];

function formatDate(inputDate) {
  const date = new Date(inputDate);

const year = date.getFullYear();
const month = String(date.getMonth() + 1).padStart(2, "0");
const day = String(date.getDate()).padStart(2, "0");
const hours = String(date.getHours()).padStart(2, "0");
const minutes = String(date.getMinutes()).padStart(2, "0");

return `${year}.${month}.${day} ${hours}:${minutes}`;
}


function getRandomColor() {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++ ) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}



export default function ChartComponent(props) {

const [ticketList,setTicketList]=useState(null);
const [player,setPlayer]=useState(null)

const [playerTicketCound,setPlayerTicketCount]=useState(null)

const [maxHits,setmaxHits]=useState(null)


const getPlayerTicketNum = async () => {
    try {
      const playerTicketInfo = await fetch(`${process.env.REACT_APP_SERVER_URL}/getPlayerTicketCount`);
      
      if (!playerTicketInfo.ok) {
        throw new Error("Hiba történt a kérés során");
      }
      
      const { getPlayerTicketInfo } = await playerTicketInfo.json();
  
      console.log("playerTicketInfo", getPlayerTicketInfo);
      setPlayerTicketCount(getPlayerTicketInfo);
      // Ide írd azt a kódot, amivel feldolgozod az adatokat
  
    } catch (error) {
      console.error(error);
      // Kezeld a hibát itt
    }
  }

  const getMaxHits = async () => {
    try {
      const playerTicketInfo = await fetch(`${process.env.REACT_APP_SERVER_URL}/getMaxHits`);
      
      if (!playerTicketInfo.ok) {
        throw new Error("Hiba történt a kérés során");
      }
      
      const { getPlayerTicketInfo } = await playerTicketInfo.json();
  
      console.log("playerTicketInfo", getPlayerTicketInfo);
      setmaxHits(getPlayerTicketInfo);
      // Ide írd azt a kódot, amivel feldolgozod az adatokat
  
    } catch (error) {
      console.error(error);
      // Kezeld a hibát itt
    }
  }


  
  const [ticketResultList,setTicketResultList] = useState(null);
  const getTicketResultCount = async () => {
    try {
      const playerTicketInfo = await fetch(`${process.env.REACT_APP_SERVER_URL}/getResultTicketCount`);
      
      if (!playerTicketInfo.ok) {
        throw new Error("Hiba történt a kérés során");
      }
      
      const { getPlayerTicketInfo } = await playerTicketInfo.json();
  
      console.log("Ticket Result List", getPlayerTicketInfo);
      setTicketResultList(getPlayerTicketInfo);
      // Ide írd azt a kódot, amivel feldolgozod az adatokat
  
    } catch (error) {
      console.error(error);
      // Kezeld a hibát itt
    }
  }






  const getTicketList = async () => {
    try {
      const requestData = {
        tables: ["ticket","player"],
        joins: ["ticket.PlayerId = player.playerId"],
        conditions: [],
        selectedColumns: ["*"],
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/getJoinedData`,
        requestData
      );
      console.log("result", response.data); // Itt kezelheti a választ

     
  
      setTicketList(response.data); // Ez fogja beállítani a rendezett adatokat az állapotban
    } catch (error) {
      console.error(error);
    }
  };


  const getPlayerList = async () => {
    try {
      const requestData = {
        tables: ["player"],
        joins: [],
        conditions: [`RoleId=1`],
        selectedColumns: ["*"],
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/getJoinedData`,
        requestData
      );
      console.log("result", response.data); // Itt kezelheti a választ

     
  
      setPlayer(response.data); // Ez fogja beállítani a rendezett adatokat az állapotban
    } catch (error) {
      console.error(error);
    }
  };


  const [maxWithdraw,setMaxWithdraw] = useState(null);
  const getWithDrawalSumma = async () => {
    try {
      const getSummaWithDraw = await fetch(`${process.env.REACT_APP_SERVER_URL}/getWithdrawSumma`);
  
      if (!getSummaWithDraw.ok) {
        throw new Error("Hiba történt a kérés során");
      }
  
      const response = await getSummaWithDraw.json(); // Válasz adatok elérése
      console.log("get withdraw summa", response);
        setMaxWithdraw(response.TotalResultValue);
      
  
    } catch (error) {
      console.error(error);
      // Kezeld a hibát itt
    }
  };


  const [summaHits,setSummaHits] = useState(null);
  const getSummaHits = async () => {
    try {
      const getSummaWithDraw = await fetch(`${process.env.REACT_APP_SERVER_URL}/getHitsSumma`);
  
      if (!getSummaWithDraw.ok) {
        throw new Error("Hiba történt a kérés során");
      }
  
      const response = await getSummaWithDraw.json(); // Válasz adatok elérése
      console.log("get summa hits ", response);
      setSummaHits(response.TotalResultValue);
      
  
    } catch (error) {
      console.error(error);
      // Kezeld a hibát itt
    }
  };


  const [playerSummWin,setplayerSummWin] = useState(null);
  const getPlayerSummWin = async () => {
    try {
      const getSummaWithDraw = await fetch(`${process.env.REACT_APP_SERVER_URL}/getPlayerSummaWinResult`);
  
      if (!getSummaWithDraw.ok) {
        throw new Error("Hiba történt a kérés során");
      }
  
      const response = await getSummaWithDraw.json(); // Válasz adatok elérése
      console.log("get summa hits ", response);
      setplayerSummWin(response.getPlayerTicketInfo);
      
  
    } catch (error) {
      console.error(error);
      // Kezeld a hibát itt
    }
  };


  const [playerBalanceSumma,setPlayerBalanceSumma] = useState(null);
  const getPlayerBalanceSumma = async () => {
    try {
      const getSummaWithDraw = await fetch(`${process.env.REACT_APP_SERVER_URL}/getPlayerCurrency`);
  
      if (!getSummaWithDraw.ok) {
        throw new Error("Hiba történt a kérés során");
      }
  
      const response = await getSummaWithDraw.json(); // Válasz adatok elérése
      console.log("get summa hits ", response);
      setPlayerBalanceSumma(response.TotalResultValue);
      
  
    } catch (error) {
      console.error(error);
      // Kezeld a hibát itt
    }
  };


  const [ticketSumNumber,setTicketSumNumber] = useState(null);
  const getTicketSummaNumber = async () => {
    try {
      const getSummaWithDraw = await fetch(`${process.env.REACT_APP_SERVER_URL}/getTicketNum`);
  
      if (!getSummaWithDraw.ok) {
        throw new Error("Hiba történt a kérés során");
      }
  
      const response = await getSummaWithDraw.json(); // Válasz adatok elérése
      console.log("get summa hits ", response);
      setTicketSumNumber(response.TotalResultValue);
      
  
    } catch (error) {
      console.error(error);
      // Kezeld a hibát itt
    }
  };




  
useEffect(()=>{
  getTicketList();
  getPlayerList();
  getMaxHits();
  getPlayerTicketNum();
  getSummaHits();
  getWithDrawalSumma();
  getTicketSummaNumber();
  getPlayerBalanceSumma();
  getTicketResultCount();
  getPlayerSummWin();
},[])


 


  return (
    <div className='bg-gray-100 h-screen '
  
    > 
 
    <div className='px-6 py-6'> 
   
    </div>

      <div className='bg-gray-100 text-gray-900 ' style={{ height: 600, width: '100%' }}>
 
<div className='grid grid-cols-2'>
    <div className='col gap-2 px-3'>
    <Bar
  options={{
    plugins: {
        title: {
            display: true,
            display: true,
            text: 'Játékosok és egyenleg', // Főcím mindkét irányba
            padding: {
              top: 10, // Főcím felső paddingje
              bottom: 30, // Főcím alsó paddingje
            },
        },
        legend: {
            display: false
        }
    },
    indexAxis: 'y', // Fő cím mindkét irányba
    responsive: true,
    maintainAspectRatio: false, // Diagram szélessége
}}
datasetIdKey='id'
height="10%"
width="25%"
data={{
    labels: player?.map((item) => item.nickName),
    datasets: [
        {
            id: 1,
            label: 'ACSE',
            data: player?.map((item) => item.balance),
        }
    ],
}}
/>
    </div>

     <div className='col px-2 '>
     <Bar
  options={{
    plugins: {
        title: {
            display: true,
            display: true,
            text: 'Játékosok szelvényszáma', // Főcím mindkét irányba
            padding: {
              top: 10, // Főcím felső paddingje
              bottom: 30, // Főcím alsó paddingje
            },
        },
        legend: {
            display: false
        }
    }
}}
datasetIdKey='id'
height="10%"
width="25%"
data={{
    labels: playerTicketCound?.map((item) => item.nickName),
    datasets: [
        {
            id: 1,
            label: 'Darab',
            data: playerTicketCound?.map((item) => item.TicketCount),
        }
    ],
}}
/>
   
    </div>
</div>




<div className='grid grid-cols-2'>
    <div className='col gap-2 px-3'>
    <Bar
  options={{
    plugins: {
        title: {
            display: true,
            display: true,
            text: 'Játékosok legmagasabb nyereménye', // Főcím mindkét irányba
            padding: {
              top: 10, // Főcím felső paddingje
              bottom: 30, // Főcím alsó paddingje
            },
        },
        legend: {
            display: false
        }
    },
    indexAxis: 'y', // Fő cím mindkét irányba
    responsive: true,
    maintainAspectRatio: false, // Diagram szélessége
}}
datasetIdKey='id'
height="10%"
width="25%"
data={{
    labels: maxHits?.map((item) => item.nickName),
    datasets: [
        {
            id: 1,
            label: 'Acse',
            data: maxHits?.map((item) => item.MaxResultValue),
            backgroundColor: '#ff9999',
        }
    ],
}}
/>
    </div>

     <div className='col px-2 py-3'>
     <div className='px-8 py-6'> 
   
   </div>


   
   <p className='italic text-gray-700 font-semibold text-md px-6 py-1'>Játékosok összesítő táblázata:</p>
     <table className="min-w-full divide-y divide-gray-300 shadow-xl py-6">
        
                <thead className="bg-red-100">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                       Össz. Pénz
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Szelvények(db)
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Találatok száma
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Összes nyeremény
                    </th>
                  
                  
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-600 italic">{playerBalanceSumma} Acse</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-600 italic">{ticketSumNumber} db </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-600 italic">{summaHits} db </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-600 italic">{maxWithdraw} Acse </td>
      
                </tbody>
                </table>
   
    </div>
</div>



<div className='px-8 py-6'> 
   
   </div>

<div className='grid grid-cols-2 py-3'>
    <div className='col gap-2 px-3 min-w-full'>
    <Bar
  options={{
    plugins: {
        title: {
            display: true,
            display: true,
            text: 'Szelvények találati száma', // Főcím mindkét irányba
            padding: {
              top: 10, // Főcím felső paddingje
              bottom: 30, // Főcím alsó paddingje
            },
        },
        legend: {
            display: false
        }
    },
    indexAxis: 'y', // Fő cím mindkét irányba
    responsive: true,
    maintainAspectRatio: false, // Diagram szélessége
}}
datasetIdKey='id'
height="15%"
width="25%"
data={{
    labels: ticketResultList?.map((item) => item.Result),
    datasets: [
        {
            id: 1,
            label: 'Darab',
            data: ticketResultList?.map((item) => item.Result_Count),
            backgroundColor:  'rgba(153, 102, 255, 0.2)',
        }
    ],
}}
/>
    </div>

     <div className='col px-2 min-w-full '>
    


     <Bar
  options={{
    plugins: {
        title: {
            display: true,
            display: true,
            text: 'Összenyeremény játékosonként', // Főcím mindkét irányba
            padding: {
              top: 10, // Főcím felső paddingje
              bottom: 30, // Főcím alsó paddingje
            },
        },
        legend: {
            display: false
        }
    }
}}
datasetIdKey='id'
height="10%"
width="25%"
data={{
    labels: playerSummWin?.map((item) => item.nickName),
    datasets: [
        {
          id: 1,
          label: 'Acse',
          data: playerSummWin?.map((item) => item.TotalWinnings),
          backgroundColor: '#ff9999',
        },
      ],
}}
/>
   
    </div>
</div>



  

  </div>


    </div>
    
  );
}